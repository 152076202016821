class OpalSearchJs{
    constructor(){
        this.initOpalSearch();
    }

    initOpalSearch(){
        $(".dropdown-toggle-overlay").on('click', function(){
            $($(this).data( 'target' )).addClass("active");
        });

        $(".dropdown-toggle-button").on('click', function(){
            $($(this).data( 'target' )).removeClass("active");
            return false;
        });
    }
}