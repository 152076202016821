class OpalOffcanvasMenuJs{
	constructor(){
		this.initOffcanvasmenu();
	}

	initOffcanvasmenu(){
		$('[data-toggle="offcanvas"], .btn-offcanvas').on('click', function () { 
			$('.row-offcanvas').toggleClass('active');
			$('#pbr-off-canvas').toggleClass('active');
		});

		$("#main-menu-offcanvas .caret").on('click', function(){
			 $("#main-menu-offcanvas .dropdown").removeClass('open');
			$(this).parent().addClass('open');
			return false;

		});

		$('.showright').on('click',  function(){
			 $('.offcanvas-showright').toggleClass('active');
		});
	}
}