class OpalPrettyPhotoJs{
	constructor(){
		this.initPrettyPhoto();
	}

	initPrettyPhoto(){
		$("a[rel^='prettyPhoto[pp_gal]']").prettyPhoto({
			social_tools:false,
			deeplinking:false,
		});
	}
}