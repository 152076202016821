class OpalCarouselJs{
	constructor(){
		this.initOwlCarousel();
	}

	initOwlCarousel(){
		/**
		 * Automatic apply  OWL carousel
		 */
		$(".owl-carousel-play .owl-carousel").each( function(){
			let config = {
				navigation : false, // Show next and prev buttons
				slideSpeed : 300,
				paginationSpeed : 400,
				pagination : $(this).data( 'pagination' ),
				autoHeight: true,
				pagination : true,
				afterInit : function(){
					jQuery(".overlap-carousel-property .owl-carousel .owl-item.active:eq(0)").addClass('sp-selected');

					if (jQuery('.overlap-carousel-property .owl-wrapper .active').length > 3) {
						jQuery('.overlap-carousel-property .owl-wrapper .active:last,.overlap-carousel-property .owl-wrapper .active:first').addClass('shadow');
					}
				},
				afterMove: function() {
					let current = Number(this.owl.currentItem) + 1;

					jQuery(".overlap-carousel-property .owl-carousel .owl-item").removeClass('sp-selected');
					jQuery(".overlap-carousel-property .owl-carousel .owl-item.active:eq(0)").addClass('sp-selected');

					jQuery('.overlap-carousel-property .owl-wrapper .owl-item').removeClass('shadow');
					if (jQuery('.overlap-carousel-property .owl-wrapper .active').length > 3) {
						jQuery('.overlap-carousel-property .owl-wrapper .active:last,.overlap-carousel-property .owl-wrapper .active:first').addClass('shadow');
					}
				}
			};

			let owl = $(this);
			if( $(this).data('slide') == 1 ){
				config.singleItem = true;
			}else {
				config.items = $(this).data( 'slide' );
			}
			if ($(this).data('desktop')) {
				config.itemsDesktop = $(this).data('desktop');
			}
			if ($(this).data('desktopsmall')) {
				config.itemsDesktopSmall = $(this).data('desktopsmall');
			}
			if ($(this).data('tablet')) {
				config.itemsTablet = $(this).data('tablet');
			}
			if ($(this).data('tabletsmall')) {
				config.itemsTabletSmall = $(this).data('tabletsmall');
			}
			if ($(this).data('mobile')) {
				config.itemsMobile = $(this).data('mobile');
			}
			$(this).owlCarousel( config );
			$('.left',$(this).parent()).on('click', function(){
				  owl.trigger('owl.prev');
				  return false;
			});
			$('.right',$(this).parent()).on('click', function(){
				owl.trigger('owl.next');
				return false;
			});
		});

		/**
		 * Video carousel
		 */
		$('.owl-carousel .image-video-wrapper .icon').click(function(){
			let video_link = $(this).parent().parent().data('video');
			let self = $(this);
			if ( video_link ) {
				$.ajax({
					url: dropzoneAjax.ajaxurl,
					type:'POST',
					dataType: 'html',
					data:  'action=video_display&video_link=' + video_link
				}).done(function(reponse) {
					// self.parent().parent().hide();
					self.parent().parent().parent().find('.video-wrapper').html( reponse );
					self.parent().parent().parent().find('.video-wrapper').css('z-index', '1');
				});
				return false;
			}
		});
	}
}