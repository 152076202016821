class OpalIsotopeJs{
	constructor(){
		this.initIsotope();
	}

	initIsotope(){
		/*---------------------------------------------- 
		 * Play Isotope masonry
		 *----------------------------------------------*/  
		jQuery('.isotope-masonry').each(function(){  
			let $container = jQuery(this);
			
			$container.imagesLoaded( function(){
				$container.isotope({
					itemSelector : '.masonry-item',
					transformsEnabled: true         // Important for videos
				}); 
			});
		});

		/*---------------------------------------------- 
		 *    Apply Filter        
		 *----------------------------------------------*/
		jQuery('.isotope-filter li a').on('click', function(){

			let parentul = jQuery(this).parents('ul.isotope-filter').data('related-grid');
			jQuery(this).parents('ul.isotope-filter').find('li a').removeClass('active');
			jQuery(this).addClass('active');
			let selector = jQuery(this).attr('data-option-value');
			jQuery('#'+parentul).isotope({ filter: selector }, function(){ });
			
			return(false);
		});
	}
}