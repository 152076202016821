class OpalCounterJs{
    constructor(){
        this.initCounter();
    }

    initCounter(){
        if($('.counterUp').length > 0){
            $('.counterUp').counterUp({
                delay: 10,
                time: 800
            });
        }
    }
}