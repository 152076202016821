class OpalBookingValidate {
    constructor() {
        if ($('.dropzone-booking-product').length > 0) {
            this.init();
        }
    }

    init() {
        $('.single_add_to_cart_button').prop('disabled', true);
        $('[date-picker]', '.dropzone-booking-product').on('change', this.checkValidate);
        $('[time-picker]', '.dropzone-booking-product').on('change', this.checkValidate)
    }

    checkValidate(){
        let date = $('[date-picker]', '.dropzone-booking-product').val();
        let time = $('[time-picker]', '.dropzone-booking-product').val();
        if(date && date !== '__/__/____' && time && time !== '__:__' && $('#booking_total_price').val()){
            $('.single_add_to_cart_button').prop('disabled', false);
        }
    }
}