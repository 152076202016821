class OpalDropdownJs{
    constructor() {
        this.initDropdownToggle();
    }

    initDropdownToggle() {
        $(".btn-group .dropdown-toggle").on('click', function(){
            $(this).parent().addClass('open');
            return false;
        } );
    }
}