class OpalBouncingarrowJs {
    constructor(){
        this.initBouncingarrow();
    }

    initBouncingarrow(){
        function animUp() {

            $("#arrow").animate({
                top: "20px"
            }, "slow", "swing", animDown);
        }


        function animDown() {
            $("#arrow").animate({
                top: "30px"
            }, "slow", "swing", animUp);
        }

        animUp();

        jQuery('#arrow').localScroll(1000);
    }
}