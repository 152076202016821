class OpalBacktotopJs {
	constructor(){
		this.initBacktotop();
	}

	initBacktotop(){
		jQuery(window).scroll(function(){
			if (jQuery(this).scrollTop() > 200) {
				jQuery('.scrollup').fadeIn();
			} else {
				jQuery('.scrollup').fadeOut();
			}
		});
		jQuery('.scrollup').on('click', function(){
			jQuery("html, body").animate({ scrollTop: 0 }, 600);
			return false;
		});
	}
}