class OpalEventJs{
    constructor(){
        this.initEventSearch();
    }

    initEventSearch(){
        $(".tribe-bar-search-filter, .tribe-bar-filters-inner").click( function(e) {
            e.stopPropagation(); // this stops the event from bubbling up to the body
        });

        let hide = true;
        $('body').on("click", function () {
            if (hide) $('.tribe-bar-filters').removeClass('active');
            hide = true;
        });
        // add and remove .active
        $('body').on('click', '.tribe-bar-button', function () {
            let self = $(".tribe-bar-filters");
            if (self.hasClass('active')) {
                $('.tribe-bar-filters').removeClass('active');
                return false;
            }
            $('.tribe-bar-filters').removeClass('active');
            self.toggleClass('active');
            hide = false;
        });
    }
}