class OpalFixVcJs{
	constructor(){
		this.initFixVc();
	}

	initFixVc(){
		// Fix Isotope in Booking Tab
		$('.woocommerce-tabs .gallery_tab a').on('click', (event) => {
            setTimeout( function() {
            	let $button = $(event.currentTarget);
                $($button.attr('href') + ' .wpb_gallery_slides').masonry({
                    itemSelector: '.isotope-item'
                }, 500);
            });
		})
	}
}