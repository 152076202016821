class OpalStickyHeaderJs {
	constructor(){
		this.initSticky();
	}

	initSticky(){
		if ($('.keep-header').length > 0) {
			let _menu_action = $('.keep-header').offset().top;

			let $mainbody = $('.keep-header').parents('body');
			$mainbody.addClass('has-sticky');

			let OC_Menu_Fixed = function(){
				"use strict";

				let $mainmenu = $('.keep-header');

				if( $(document).scrollTop() > _menu_action ){
				  $mainmenu.addClass('fixed');
				}else{
				  $mainmenu.removeClass('fixed');
				}
			}
			$(window).scroll(function(event) {
				OC_Menu_Fixed();
			});
		}
	}
}